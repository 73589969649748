import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import OverlayBox from './OverlayBox';
import { useState } from 'react';
import { parseDate } from '../utils/parseDate';
import { protocolMap } from '../utils/data';
import Button from './Button';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { createProtocol } from '../utils/protocolHelpers';
import * as protocolResults from '../utils/protocolConfig';
import { getProtocolString } from '../utils/getProtocolFilename';
import DateInput from './DateInput';

const StyledCreateProtocolModal = styled.div`
  .create-protocol-button-container {
    display: flex;
    justify-content: flex-end;
  }
`;

const CreateProtocolModal = ({ close, reservoir, customer }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [protocolType, setProtocolType] = useState('');
  const [protocolDate, setProtocolDate] = useState(parseDate(new Date()));
  const [protocolIdToCopyFrom, setProtocolIdToCopyFrom] = useState('');

  const copyResultsProtocolTypes = ['insp', 'kont'];

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      user: { firstName, lastName },
    } = state;
    const payload = {
      state,
      dispatch,
      reservoir,
      customer,
      protocolData: {
        dateForInspection: protocolDate,
        type: protocolType,
        typeFull: protocolMap[protocolType],
        responsibleTechnician: `${firstName} ${lastName}`,
        status: 'Ej påbörjat',
        resultsInner: protocolResults[protocolType].resultsInner,
        resultsOuter: protocolResults[protocolType].resultsOuter,
      },
    };

    if (
      copyResultsProtocolTypes.includes(protocolType) &&
      protocolIdToCopyFrom
    ) {
      const protocolToCopyFrom = reservoir.protocols.find(
        (p) => p._id === protocolIdToCopyFrom
      );
      if (protocolToCopyFrom) {
        payload.protocolData.resultsInner = protocolToCopyFrom.resultsInner;
        payload.protocolData.resultsOuter = protocolToCopyFrom.resultsOuter;
      }
    }

    const recommendedActionKeys = {
      manualCleaning: 'nextRecommendedCleaning',
      robotCleaning: 'nextRecommendedCleaning',
      filterChange: 'nextRecommendedFilterChange',
      inspection: 'nextRecommendedInspection',
      control: 'nextRecommendedControl',
    };
    Object.keys(recommendedActionKeys).forEach((key) => {
      if (reservoir.nextRecommendedAction?.[key]) {
        payload.protocolData[recommendedActionKeys[key]] =
          reservoir.nextRecommendedAction[key];
      }
    });

    createProtocol(payload);
  };

  const availableProtocolsToCopyFrom = useMemo(() => {
    const relevantProtocols = reservoir.protocols.filter(
      (protocol) => protocol.type === protocolType
    );
    return relevantProtocols;
  }, [protocolType, reservoir.protocols]);

  return (
    <StyledCreateProtocolModal>
      <OverlayBox close={close} header="Skapa protokoll" active>
        <form onSubmit={handleSubmit}>
          <select
            required
            value={protocolType}
            onChange={(e) => setProtocolType(e.target.value)}
          >
            <option value="">Välj protokolltyp</option>
            {Object.keys(protocolMap).map((key) => (
              <option key={`create_proto_${key}`} value={key}>
                {protocolMap[key]}
              </option>
            ))}
          </select>
          <DateInput
            required
            value={protocolDate}
            onChange={(e) => setProtocolDate(e.target.value)}
          />
          {copyResultsProtocolTypes.includes(protocolType) && (
            <select
              value={protocolIdToCopyFrom || ''}
              onChange={(e) => setProtocolIdToCopyFrom(e.target.value)}
            >
              <option disabled value="">
                Välj protokoll att kopiera resultat från
              </option>
              {availableProtocolsToCopyFrom.map((protocol) => (
                <option value={protocol._id} key={protocol._id}>
                  {getProtocolString(
                    protocol.dateForInspection,
                    protocol.typeFull
                  )}
                </option>
              ))}
            </select>
          )}
          <div className="create-protocol-button-container">
            <Button small type="submit">
              Skapa
            </Button>
          </div>
        </form>
      </OverlayBox>
    </StyledCreateProtocolModal>
  );
};

export default CreateProtocolModal;
