import React from 'react';

const MultilineText = ({ text, className }) => {
  const _className = className ? className : '';
  if (!Array.isArray(text)) {
    console.warn('Passed non array to MultilineText');
    return <p className={_className}>{text}</p>;
  }

  return (
    <p className={_className}>
      {text.map((line, index) => (
        <React.Fragment key={`${index}_${line}`}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </p>
  );
};

export default MultilineText;
