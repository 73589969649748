import axios from 'axios';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { colors } from '../utils/siteVars';
import Button from './Button';

const StyledLinkReservoirModal = styled.div`
  .customers-container {
    margin-top: 1rem;
    .move-reservoir-single-customer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: ${colors.lightGrey};
      padding: 0.5rem;
      border-radius: 8px;
      margin-bottom: 0.5rem;
      button {
        margin: 0;
      }
      p {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .search-box {
    position: relative;
    button {
      border: 1px solid ${colors.darkGrey};
      position: absolute;
      top: 0.3rem;
      right: 0.3rem;
      margin: 0;
    }
    input {
      min-width: 250px;
    }
  }
  .mbottom05 {
    margin-bottom: 0.5rem !important;
  }
`;

const LinkReservoirModal = ({ reservoir, customerId, finish }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [customers, setCustomers] = useState([]);
  //   const [oldCustomer, setOldCustomer] = useState()
  const [loading, setLoading] = useState(false);
  const [preselectedIndex, setPreselectedIndex] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [searchHelpText, setSearchHelpText] = useState('');

  const commitMove = (newCustomer) => {
    dispatch({ type: 'LOADING', value: true });
    const body = {
      reservoir,
      newCustomer,
    };
    axios
      .post(
        `${process.env.GATSBY_BACKEND_API_URL}customers/link-reservoir-to-another-customer`,
        body,
        {
          headers: {
            Authorization: 'Bearer ' + state.token,
          },
        }
      )
      .then(() => {
        // axios
        //   .post(
        //     `${do_url}files/${state.token}/move-reservoir-to-another-customer`,
        //     body
        //   )
        //   .then(() => {
        //     dispatch({ type: "ALERT", content: "Reservoaren flyttades" })
        //     setTimeout(() => {
        //       window.location.replace(
        //         `/reservoirs/single/?customerId=${newCustomer._id}&reservoirId=${reservoir._id}`
        //       )
        //     }, 1000)
        //     // dispatch({ type: "LOADING", value: false })
        //   })
        //   .catch(err => {
        //     console.log(err)
        //     dispatch({ type: "ALERT", content: "Något gick fel" })
        //     dispatch({ type: "LOADING", value: false })
        //   })
        dispatch({ type: 'LOADING', value: false });
        dispatch({ type: 'ALERT', content: 'Reservoaren länkades' });
        finish();
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: 'ALERT', content: 'Något gick fel' });
        dispatch({ type: 'LOADING', value: false });
      });
  };

  const unLink = (newCustomer) => {
    //
    dispatch({ type: 'LOADING', value: true });
    const body = {
      reservoir,
      newCustomer,
    };
    axios
      .post(
        `${process.env.GATSBY_BACKEND_API_URL}customers/unlink-reservoir-from-another-customer`,
        body,
        {
          headers: {
            Authorization: 'Bearer ' + state.token,
          },
        }
      )
      .then(() => {
        dispatch({ type: 'LOADING', value: false });
        dispatch({ type: 'ALERT', content: 'Reservoaren avlänkades' });
        finish();
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: 'LOADING', value: false });
        dispatch({ type: 'ALERT', content: 'Något gick fel' });
      });
  };

  const getCustomers = (e) => {
    e.preventDefault();
    if (searchString.length < 3)
      return setSearchHelpText('Skriv minst 3 tecken för att söka');
    setSearchHelpText('');
    setLoading(true);
    setPreselectedIndex(null);
    axios
      .get(
        `${
          process.env.GATSBY_BACKEND_API_URL
        }customers/?q=${searchString}&page=${0}&sortOrder=name`,
        {
          headers: {
            Authorization: 'Bearer ' + state.token,
          },
        }
      )
      .then(({ data }) => {
        console.log(data);
        const filteredCustomers = data.customers.filter(
          (c) => c._id !== customerId
        );
        // setOldCustomer(data.customers.filter(c => c._id === customerId)[0])
        const sortedCustomers = filteredCustomers.sort((a, b) => {
          return a.customerInformation.name.localeCompare(
            b.customerInformation.name
          );
        });
        setCustomers(sortedCustomers);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        dispatch({ type: 'ALERT', content: 'Kunde inte hämta kunder' });
      });
  };

  // useEffect(() => {
  //   // if (state.token) getCustomers()
  //   //eslint-disable-next-line
  // }, [reservoir, state.token, customerId])

  return (
    <StyledLinkReservoirModal>
      <p className="mbottom05">Sök efter en kund att länka reservoaren till</p>
      <form onSubmit={getCustomers} className="search-box">
        {/* <p>Välj en annan kund att länka reservoaren till</p> */}
        <input
          type="text"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder="Kundnamn"
        />
        <Button small type="submit" disabled={searchString.length < 3}>
          Sök
        </Button>
      </form>
      <div className="customers-container">
        {searchHelpText && <p>{searchHelpText}</p>}
        {loading ? (
          <p>Laddar kunder...</p>
        ) : (
          customers.map((customer, i) => (
            <div className="move-reservoir-single-customer" key={customer._id}>
              <p>{customer.customerInformation.name}</p>
              {customer.linkedReservoirIds?.includes(reservoir._id) ? (
                <Button
                  small
                  look={preselectedIndex === i ? 'danger' : ''}
                  onClick={() => {
                    if (preselectedIndex === i) {
                      unLink(customer);
                    } else {
                      setPreselectedIndex(i);
                    }
                  }}
                >
                  {preselectedIndex === i ? 'Säker?' : 'Avlänka'}
                </Button>
              ) : (
                <Button
                  small
                  look={preselectedIndex === i ? 'danger' : ''}
                  onClick={() => {
                    if (preselectedIndex === i) {
                      commitMove(customer);
                    } else {
                      setPreselectedIndex(i);
                    }
                  }}
                >
                  {preselectedIndex === i ? 'Säker?' : 'Länka hit'}
                </Button>
              )}
            </div>
          ))
        )}
      </div>
    </StyledLinkReservoirModal>
  );
};

export default LinkReservoirModal;
