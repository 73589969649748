import React, { useContext } from 'react';
import styled from 'styled-components';
import Card from './Card';
import FileUploadButton from './FileUploadButton';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { request } from '../utils/request';
import Button from './Button';
import { colors } from '../utils/siteVars';

const StyledReservoirAssetSection = styled.div`
  .image-wrapper {
    border: 1px solid ${colors.darkGrey};
    border-radius: 6px;
    background: ${colors.lightGrey};
    margin-bottom: 1rem;
    .image-actions {
      padding: 0.5rem;
      display: flex;
      place-content: flex-end;
      gap: 1rem;
    }
  }
`;

const ReservoirAssetSection = ({
  reservoir,
  customerId,
  reservoirId,
  refetch,
  assetKey,
  header,
  AssetComponent,
  fileType,
}) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const updateAssets = async (updated) => {
    await request({
      state,
      dispatch,
      path: `reservoirs/${customerId}/${reservoirId}/${assetKey}`,
      method: 'PUT',
      body: updated,
    });
    refetch();
  };

  const addAsset = async (file) => {
    const updated = [...reservoir[assetKey], file.uuid];
    updateAssets(updated);
  };

  const removeAsset = (index) => {
    const updated = [...reservoir[assetKey]];
    updated.splice(index, 1);
    updateAssets(updated);
  };

  const rearrange = (index, isUp) => {
    const updated = [...reservoir[assetKey]];
    const [rearranged] = updated.splice(index, 1);
    const newIndex = isUp ? index - 1 : index + 1;
    updated.splice(newIndex, 0, rearranged);
    updateAssets(updated);
  };

  return (
    <StyledReservoirAssetSection>
      <Card className="reservoir-images-container">
        <div className="customer-card-header">
          <h3>{header}</h3>
          <div className="customer-card-header-right-content">
            <FileUploadButton
              type={fileType}
              context={{
                customer: customerId,
                reservoir: reservoirId,
              }}
              buttonText={'Lägg till'}
              onSelect={addAsset}
            />
          </div>
        </div>
        {reservoir[assetKey].map((asset, i) => (
          <div className="image-wrapper" key={asset}>
            <AssetComponent assetId={asset} overlayOnClick />
            <div className="image-actions">
              {i !== 0 && (
                <Button noBg onClick={() => rearrange(i, true)}>
                  Flytta upp
                </Button>
              )}
              {i !== reservoir[assetKey].length - 1 && (
                <Button noBg onClick={() => rearrange(i, false)}>
                  Flytta ned
                </Button>
              )}
              <Button noBg look="danger" onClick={() => removeAsset(i)}>
                Ta bort
              </Button>
            </div>
          </div>
        ))}
      </Card>
    </StyledReservoirAssetSection>
  );
};

export default ReservoirAssetSection;
