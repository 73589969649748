import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import MediaLibrary from './MediaLibrary';

const StyledFileUploadButton = styled.div`
  .media-lib-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10;
    padding: 3rem;
    background: rgba(0, 0, 0, 0.7);
    &-inner {
      &-top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      max-height: calc(100vh - 6rem);
      overflow-x: scroll;
      background: #f2f2f2;
      padding: 2rem;
      border-radius: 8px;
      h2 {
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }
  @media (max-width: 1000px) {
    .media-lib-container {
      padding: 1rem;
      &-inner {
        padding: 1rem;
        max-height: calc(100vh - 2rem);
      }
    }
  }
`;

const FileUploadButton = ({
  type,
  onSelect,
  context,
  multiple = false,
  buttonText,
  'data-test': dataTest,
}) => {
  const [libOpen, setLibOpen] = useState(false);

  const handleSelect = (file) => {
    onSelect(file);
    setLibOpen(false);
  };

  return (
    <StyledFileUploadButton>
      <Button
        noBg
        onClick={() => setLibOpen(true)}
        type="button"
        data-test={`${dataTest}-open-lib`}
      >
        {buttonText || 'Välj fil'}
      </Button>
      {libOpen && (
        <div className="media-lib-container">
          <div className="media-lib-container-inner">
            <div className="media-lib-container-inner-top">
              <h2>Filer</h2>
              <Button noBg onClick={() => setLibOpen(false)}>
                Stäng
              </Button>
            </div>
            <MediaLibrary
              multiple={multiple}
              context={context}
              onSelect={handleSelect}
              type={type}
            />
          </div>
        </div>
      )}
    </StyledFileUploadButton>
  );
};

export default FileUploadButton;
