import { navigate } from 'gatsby-link';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Breadcrumbs from '../../components/Breadcrumbs';
import BtnNoStyle from '../../components/BtnNoStyle';
import Button from '../../components/Button';
import Card from '../../components/Card';
import InformationGrid from '../../components/InformationGrid';
import Layout from '../../components/Layout';
import LinkReservoirModal from '../../components/LinkReservoirModal';
import MoveReservoirModal from '../../components/MoveReservoirModal';
import OverlayBox from '../../components/OverlayBox';
import ReservoirForm from '../../components/ReservoirForm';
import ReservoirProtocolsList from '../../components/ReservoirProtocolsList';
import TabCard from '../../components/TabCard';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { normalizeDate, parseDate } from '../../utils/parseDate';
import { colors } from '../../utils/siteVars';
import ReservoirAssetSection from '../../components/ReservoirAssetSection';
import ImageViewer from '../../components/ImageViewer';
import ReservoirDrawingCard from '../../components/ReservoirDrawingCard';
import { Link } from 'gatsby';
import { useQuery } from '../../utils/hooks';
import { request } from '../../utils/request';
import { parseCustomerName } from '../../utils/parseCustomerName';
import MultilineText from '../../components/MultilineText';
import DateInput from '../../components/DateInput';

const StyledSingleReservoirPage = styled.div`
  .mleft05 {
    margin-left: 0.5rem;
  }
  form {
    .dual {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
  .delete-overlay-box {
    button {
      margin-right: 0.5rem;
    }
  }

  #reservoir-drawings-input {
    display: none;
  }

  .file-card {
    overflow: hidden;
    width: 100%;
    text-align: left;
    .file-card-inner {
      transition: 200ms ease-in-out;
      &:hover {
        background: ${colors.lighterBlue};
      }
      h5 {
        white-space: nowrap;
      }
    }
  }
  .handle-customers-single-customer {
    display: grid;
    grid-template-columns: auto 3rem;
    align-items: center;
    .handle-customer-btn-container {
      margin-top: 1rem;
      margin-left: 1rem;
      position: relative;
      .handle-customer-menu-container {
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 1rem;
        border-radius: 10px;
        background: ${colors.white};
        box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.1);
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        opacity: 0;
        pointer-events: none;
        transition: 100ms ease-in-out;
        z-index: 2;
        a {
          display: block;
          width: 100%;
        }
        button {
          width: 100%;
          white-space: nowrap;
        }
      }
      &:hover {
        .handle-customer-menu-container {
          opacity: 1;
          pointer-events: initial;
        }
      }
    }
    &.documents-templates {
      margin-bottom: 0.8rem;
      .customer {
        margin-top: 0.2rem;
        overflow: hidden;
        .template-filename {
          white-space: nowrap;
        }
      }
    }
  }
  .breadcrumb-buttons button {
    margin-bottom: 0.5rem;
  }
`;

const SingleReservoirPage = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const query = useQuery();

  const [protocolsWithPDF, setProtocolsWithPDF] = useState([]);
  const [reservoir, setReservoir] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [deleteReservoirPending, setDeleteReservoirPending] = useState({
    reservoir: {},
    active: false,
  });
  const [moveReservoirModal, setMoveReservoirModal] = useState({
    active: false,
    customerId: '',
    reservoir: {},
  });
  const [linkReservoirModal, setLinkReservoirModal] = useState({
    active: false,
    customerId: '',
    reservoir: {},
    finish: () => {},
  });
  const [editData, setEditData] = useState({
    active: false,
    formData: null,
  });

  const [overlayImage, setOverlayImage] = useState({
    active: false,
    image: '',
  });

  const [commentEdit, setCommentEdit] = useState({
    active: false,
    comment: [],
  });

  const [nextRecommendedActionEdit, setNextRecommendedActionEdit] = useState({
    active: false,
    filterChange: '',
    robotCleaning: '',
    manualCleaning: '',
    statusCheck: '',
    control: '',
    inspection: '',
  });

  const {
    basicInformation,
    establishment,
    hatch,
    ventilation,
    cleaning,
  } = reservoir;

  let newSummerStatus = '-';
  if (basicInformation?.summerStatusNew) {
    if (basicInformation?.summerStatusNew === 'summer') {
      newSummerStatus = 'Sommarjobb';
    } else if (basicInformation?.summerStatusNew === 'all-year') {
      newSummerStatus = 'Året runt jobb';
    } else {
      newSummerStatus = '-';
    }
  } else {
    if (basicInformation?.isWinterWork) {
      newSummerStatus = 'Året runt jobb';
    } else {
      newSummerStatus = 'Sommarjobb';
    }
  }

  const informationCardContents2 = [
    <InformationGrid
      pairs={[
        { h: 'Namn', p: basicInformation?.name },
        { h: 'Skyddsobjekt', p: basicInformation?.protected ? 'Ja' : 'Nej' },
        basicInformation?.gps?.lat && basicInformation?.gps?.lon
          ? {
              h: 'Koordinater',
              p:
                basicInformation.gps.lat.toFixed(7) +
                ', ' +
                basicInformation.gps.lon.toFixed(7),
            }
          : null,
        basicInformation?.gps?.lat && basicInformation?.gps?.lon
          ? {
              h: 'Karta',
              p: 'Öppna i Maps',
              href: `https://www.google.com/maps/search/?api=1&query=${basicInformation.gps.lat},${basicInformation.gps.lon}`,
            }
          : null,
        basicInformation?.gps?.lat && basicInformation?.gps?.lon
          ? {
              h: 'Karta',
              p: 'Öppna i Waze',
              href: `https://www.waze.com/ul?ll=${basicInformation.gps.lat},${basicInformation.gps.lon}&navigate=yes&zoom=17`,
            }
          : null,
        {
          h: 'Adress',
          p: basicInformation?.adress.map((p, i) => (
            <React.Fragment key={p + i}>
              {p}
              <br />
            </React.Fragment>
          )),
        },
        { h: 'Typ', p: basicInformation?.type },
        { h: 'Utförande', p: basicInformation?.implementation },
        {
          h: 'Höjd ö marknivå',
          p: basicInformation?.heightOverGround
            ? basicInformation?.heightOverGround + 'm'
            : '-',
        },
        {
          h: 'Invändig höjd',
          p: basicInformation?.innerHeight
            ? basicInformation?.innerHeight + 'm'
            : '-',
        },
        {
          h: 'Volym 1',
          p: basicInformation?.volume ? basicInformation?.volume + 'm³' : '-',
        },
        {
          h: 'Volym 2',
          p: basicInformation?.volume2 ? basicInformation?.volume2 + 'm³' : '-',
        },
        {
          h: 'Volym 3',
          p: basicInformation?.volume3 ? basicInformation?.volume3 + 'm³' : '-',
        },
        {
          h: 'Volym 4',
          p: basicInformation?.volume4 ? basicInformation?.volume4 + 'm³' : '-',
        },
        {
          h: 'Bottenyta 1',
          p: basicInformation?.area ? basicInformation?.area + 'm²' : '-',
        },
        {
          h: 'Bottenyta 2',
          p: basicInformation?.area2 ? basicInformation?.area2 + 'm²' : '-',
        },
        {
          h: 'Bottenyta 3',
          p: basicInformation?.area3 ? basicInformation?.area3 + 'm²' : '-',
        },
        {
          h: 'Bottenyta 4',
          p: basicInformation?.area4 ? basicInformation?.area4 + 'm²' : '-',
        },
        { h: 'Fack (antal)', p: basicInformation?.compartments?.quantity },
        { h: 'Fack (namn)', p: basicInformation?.compartments?.name },
        {
          h: 'Invändigt material',
          p:
            basicInformation?.innerMaterial === 'Annat'
              ? basicInformation?.innerMaterialOther
              : basicInformation?.innerMaterial || 'Ej valt ännu',
        },
        {
          h: 'Infodring',
          p:
            basicInformation?.feeding === 'Annat'
              ? basicInformation?.feedingOther
              : basicInformation?.feeding || 'Ej valt ännu',
        },
        {
          h: 'El',
          p: basicInformation?.electricity.hasElectricity
            ? basicInformation?.electricity.type
            : 'Nej',
        },
        {
          h: 'Vattentillgång',
          p: basicInformation?.water.hasWater ? 'Ja' : 'Nej',
        },
        basicInformation?.water.hasWater
          ? {
              h: 'Vattenutkast',
              p: basicInformation?.water.waterOut ? 'Ja' : 'Nej',
            }
          : null,
        basicInformation?.water.hasWater
          ? {
              h: 'Vattenpost',
              p: basicInformation?.water.waterPost ? 'Ja' : 'Nej',
            }
          : null,
        {
          h: 'Sommarjobb/Året runt jobb',
          p: newSummerStatus
            ? newSummerStatus === '-'
              ? 'Ej valt ännu'
              : newSummerStatus
            : 'Ej valt ännu',
        },
      ]}
    />,
    <InformationGrid
      pairs={[
        { h: 'Kranbil', p: establishment?.crane ? 'Ja' : 'Nej' },
        { h: 'Hiss', p: establishment?.elevator ? 'Ja' : 'Nej' },
        {
          h: 'Fastmonterad kranarm på tak',
          p: establishment?.telferCrane ? 'Ja' : 'Nej',
        },
        {
          h: 'Invändig telfer finns på plats',
          p: establishment?.innerTelfer ? 'Ja' : 'Nej',
        },
        {
          h: (
            <>
              {'Telfer med kranarm som'} <br />
              {'tas med av H2O Teknik'}
            </>
          ),
          p: establishment?.broughtTelfer ? 'Ja' : 'Nej',
        },
        {
          h: 'Avstånd parkering - reservoar',
          p: establishment?.parkingReservoirDistance
            ? establishment?.parkingReservoirDistance + 'm'
            : '-',
        },
        {
          h: 'Etableringstid robot',
          p: establishment?.establishmentTime
            ? establishment?.establishmentTime + 'h'
            : '-',
        },
        {
          h: 'Etableringstid manuell rengöring',
          p: establishment?.establishmentTimeManual
            ? establishment?.establishmentTimeManual + 'h'
            : '-',
        },
        {
          h: 'Krävs ometablering',
          p: establishment?.reEstablishmentRequired ? 'Ja' : 'Nej',
        },
        {
          h: 'Pumpgrop under stege',
          p: establishment?.pumpPitUnderLadder || 'Ej valt ännu',
        },
      ]}
    />,
    <InformationGrid
      pairs={[
        { h: 'Manlucka', p: hatch?.manlucka.hasManlucka ? 'Ja' : 'Nej' },
        hatch?.manlucka.hasManlucka
          ? {
              h: 'Manlucka märke',
              p:
                hatch?.manlucka.brand !== 'Annat'
                  ? hatch?.manlucka.brand
                  : hatch?.manlucka.brandOther,
            }
          : null,
        hatch?.manlucka.hasManlucka
          ? {
              h: 'Säkerhetslucka',
              p: hatch?.safetyHatch.hasSafetyHatch
                ? 'Monterad ' + parseDate(hatch?.safetyHatch.dateInstalled)
                : 'Nej',
            }
          : null,
        hatch?.manlucka.hasManlucka
          ? {
              h: 'Mått manlucka',
              p: `${hatch?.measurments.w || '-'} x ${
                hatch?.measurments.h || '-'
              } mm`,
            }
          : null,
      ]}
    />,
    <InformationGrid
      pairs={[
        { h: 'Ventilation', p: ventilation?.hasVentilation ? 'Ja' : 'Nej' },
        ventilation?.hasVentilation
          ? { h: 'Modell', p: ventilation?.type }
          : null,
        ventilation?.hasVentilation && ventilation?.type === 'H2O-vent'
          ? { h: 'Monterad datum', p: parseDate(ventilation?.h2oVentDate) }
          : null,
        { h: 'Antal', p: ventilation?.quantity },
      ]}
    />,
    <InformationGrid
      pairs={[
        {
          h: 'Robotrengöring möjlig',
          p: cleaning?.robotPossible ? 'Ja' : 'Nej',
        },
        cleaning?.robotPossible
          ? { h: 'Rekommenderad robot', p: cleaning?.robotType }
          : { h: 'Varför ej möjlig', p: cleaning?.noRobotReason },
        cleaning?.robotType === 'VR 50'
          ? { h: 'VR 50 anledning', p: cleaning?.vr50reason }
          : null,
        {
          h: 'Tid för robotrengöring',
          p: cleaning?.cleaningTime ? cleaning?.cleaningTime + 'h' : '-',
        },
        {
          h: 'Tid för manuell rengöring',
          p: cleaning?.cleaningTimeManual
            ? cleaning?.cleaningTimeManual + 'h'
            : '-',
        },
        {
          h: 'Tvärgående bottenrör',
          p: cleaning?.diagonalPipe
            ? cleaning.diagonalPipe
            : cleaning?.hasDiagonalPipe
            ? 'Ja'
            : 'Nej',
        },
        cleaning?.hasDiagonalPipe
          ? {
              h: 'Möjlighet att knuffa robot över rör',
              p: cleaning?.canPushRobotOverPipe ? 'Ja' : 'Nej',
            }
          : null,
        cleaning?.hasDiagonalPipe
          ? {
              h: 'Möjlighet att köra runt rör',
              p: cleaning?.canDriveAroundPipe ? 'Ja' : 'Nej',
            }
          : null,
        cleaning?.hasDiagonalPipe
          ? {
              h: 'Möjlighet att köra under rör',
              p: cleaning?.canDriveUnderPipe ? 'Ja' : 'Nej',
            }
          : null,
        {
          h: 'Får tripod plats',
          p: cleaning?.tripodSpace ? cleaning?.tripodSpace : 'Ej valt ännu',
        },
        {
          h: 'Flotte',
          p: cleaning?.floater ? cleaning?.floater : 'Ej valt ännu',
        },
      ]}
    />,
  ];

  const deleteReservoir = async () => {
    const customerNameParsed = parseCustomerName(customerName);
    const reservoirNameParsed = parseCustomerName(
      deleteReservoirPending.reservoir.basicInformation?.name
    );
    const doEndpoint = `files/{token}/reservoir/delete/${customerNameParsed}/${reservoirNameParsed}/${query.customerId}/${deleteReservoirPending.reservoir._id}`;

    await request({
      state,
      dispatch,
      path: `customers/reservoirs/delete/${query.customerId}/${deleteReservoirPending.reservoir._id}`,
      method: 'DELETE',
    });

    await request({
      state,
      dispatch,
      isDoServer: true,
      path: doEndpoint,
      successText: 'Reservoar raderad',
      method: 'DELETE',
    });

    setTimeout(() => {
      navigate(`/reservoirs/?customerId=${query.customerId}`);
    }, 500);
  };

  const saveMainComment = async () => {
    const { reservoirId, customerId } = query;
    const body = {
      reservoir: { ...reservoir, comments: commentEdit.comment },
    };
    await request({
      state,
      dispatch,
      path: `customers/reservoirs/update/${customerId}/${reservoirId}`,
      method: 'POST',
      body,
      successText: 'Kommentar uppdaterad',
    });
    getReservoir();
    setCommentEdit({
      ...commentEdit,
      active: false,
    });
  };

  const getReservoir = async () => {
    const { reservoirId, customerId } = query;
    if (!reservoirId || !customerId) return;

    const { data } = await request({
      state,
      dispatch,
      path: `customers/reservoirs/single/${customerId}/${reservoirId}`,
    });

    setReservoir(data.reservoir);
    setCustomerName(data.customerName);

    const customerNameParsed = parseCustomerName(data.customerName);
    const reservoirNameParsed = parseCustomerName(
      data.reservoir.basicInformation.name
    );
    const doEndpoint = `protocols/{token}/get-pdf-id-match/${customerNameParsed}/${reservoirNameParsed}`;

    const { data: doData } = await request({
      state,
      dispatch,
      isDoServer: true,
      path: doEndpoint,
    });
    setProtocolsWithPDF(doData);
  };

  const editInit = () => {
    setEditData({
      active: true,
      formData: reservoir,
    });
  };

  const updateReservoir = async (formData, isAutoSave) => {
    const { reservoirId, customerId } = query;
    const body = {
      reservoir: formData,
    };
    let updateDoName = false;
    if (reservoir.basicInformation.name !== formData.basicInformation.name) {
      updateDoName = true;
    }

    await request({
      state,
      dispatch,
      path: `customers/reservoirs/update/${customerId}/${reservoirId}`,
      method: 'POST',
      body,
      skipLoader: !!isAutoSave,
    });

    if (updateDoName) {
      await request({
        state,
        dispatch,
        isDoServer: true,
        skipLoader: !!isAutoSave,
        method: 'POST',
        path: `files/{token}/reservoir/update-name/${parseCustomerName(
          customerName
        )}/${parseCustomerName(
          reservoir.basicInformation.name
        )}/${parseCustomerName(formData.basicInformation.name)}`,
      });
    }

    if (!isAutoSave) {
      dispatch({ type: 'ALERT', content: 'Reservoar uppdaterad' });
      getReservoir();
      setEditData({
        active: false,
        formData: null,
      });
    }
  };

  const moveReservoirInit = () => {
    setMoveReservoirModal({
      ...moveReservoirModal,
      active: true,
      reservoir: reservoir,
      customerId: query.customerId,
    });
  };

  const linkReservoirInit = () => {
    setLinkReservoirModal({
      ...linkReservoirModal,
      active: true,
      reservoir: reservoir,
      customerId: query.customerId,
      finish: () => {
        setLinkReservoirModal({
          ...linkReservoirModal,
          active: false,
          reservoir: {},
          finish: () => {},
        });
      },
    });
  };

  const updateRecommendedActions = async (e) => {
    e.preventDefault();
    dispatch({ type: 'LOADING', value: true });
    const { reservoirId, customerId } = query;
    const body = {
      reservoir: { ...reservoir },
      generateRecommendedActionNotification: true,
    };
    body.reservoir.nextRecommendedAction = {
      filterChange: nextRecommendedActionEdit.filterChange,
      robotCleaning: nextRecommendedActionEdit.robotCleaning,
      manualCleaning: nextRecommendedActionEdit.manualCleaning,
      control: nextRecommendedActionEdit.control,
      statusCheck: nextRecommendedActionEdit.statusCheck,
      inspection: nextRecommendedActionEdit.inspection,
    };

    await request({
      state,
      dispatch,
      method: 'POST',
      path: `customers/reservoirs/update/${customerId}/${reservoirId}`,
      body,
      successText: 'Åtgärder uppdaterade',
    });

    getReservoir();
    setNextRecommendedActionEdit({
      ...nextRecommendedActionEdit,
      active: false,
    });
  };

  useEffect(() => {
    dispatch({ type: 'LOADING', value: true });
    if (state.token) {
      getReservoir();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  return (
    <Layout>
      {basicInformation ? (
        <StyledSingleReservoirPage>
          <h1 className="page-header" data-test="reservoir-page-header">
            {basicInformation.name} - {customerName}
          </h1>
          <Breadcrumbs
            crumbs={[
              { to: '/home', p: 'Hem' },
              { to: '/customers', p: 'Kunder' },
              {
                to: `/customers/single/?id=${query.customerId}`,
                p: customerName,
              },
              {
                to: `/reservoirs/?customerId=${query.customerId}`,
                p: 'Reservoarer',
              },
              { p: basicInformation.name },
            ]}
            rightContent={
              <div className="breadcrumb-buttons">
                <Link
                  to={`/media/?customer=${query.customerId}&reservoir=${reservoir._id}`}
                >
                  <Button small>Filer</Button>
                </Link>
                <Button small onClick={editInit}>
                  Redigera
                </Button>
                <Button
                  small
                  look="danger"
                  onClick={() =>
                    setDeleteReservoirPending({
                      ...deleteReservoirPending,
                      active: true,
                      reservoir: reservoir,
                    })
                  }
                >
                  Radera
                </Button>
              </div>
            }
          />
          <div className="single-customer-inner">
            <div className="single-customer-left-content">
              <TabCard
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                contents={informationCardContents2}
                tabs={[
                  'Grunduppgifter',
                  'Etablering',
                  'Lucka',
                  'Ventilation',
                  'Rengöring',
                ]}
              />
              <Card>
                <div className="customer-card-header">
                  <h3>Kommentarer</h3>
                  <div className="customer-card-header-right-content">
                    {commentEdit.active ? null : (
                      <Button
                        noBg
                        onClick={() =>
                          setCommentEdit({
                            ...commentEdit,
                            active: true,
                            comment: reservoir.comments,
                          })
                        }
                      >{`Redigera`}</Button>
                    )}
                  </div>
                </div>

                {commentEdit.active ? (
                  <>
                    <textarea
                      value={commentEdit.comment.join('\n')}
                      onChange={(e) => {
                        setCommentEdit({
                          ...commentEdit,
                          comment: e.target.value.split('\n'),
                        });
                      }}
                    />
                    <Button
                      small
                      onClick={() => saveMainComment()}
                    >{`Spara`}</Button>
                    <Button
                      style={{ marginLeft: '0.5rem' }}
                      small
                      onClick={() =>
                        setCommentEdit({ ...commentEdit, active: false })
                      }
                    >
                      Avbryt
                    </Button>
                  </>
                ) : (
                  <div className="customer-main-comment">
                    {reservoir.comments?.length > 0 ? (
                      <MultilineText text={reservoir.comments} />
                    ) : (
                      'Det finns ingen kommentar ännu'
                    )}
                  </div>
                )}
              </Card>
              <Card>
                <div className="customer-card-header">
                  <h3>Nästa rekommenderade åtgärd</h3>
                  <div className="customer-card-header-right-content">
                    <Button
                      noBg
                      onClick={() =>
                        setNextRecommendedActionEdit({
                          active: true,
                          robotCleaning:
                            reservoir.nextRecommendedAction?.robotCleaning ||
                            '',
                          control:
                            reservoir.nextRecommendedAction?.control || '',
                          manualCleaning:
                            reservoir.nextRecommendedAction?.manualCleaning ||
                            '',
                          statusCheck:
                            reservoir.nextRecommendedAction?.statusCheck || '',
                          filterChange:
                            reservoir.nextRecommendedAction?.filterChange || '',
                          inspection:
                            reservoir.nextRecommendedAction?.inspection || '',
                        })
                      }
                    >
                      Ändra
                    </Button>
                  </div>
                </div>

                <InformationGrid
                  pairs={[
                    {
                      h: 'Filterbyte',
                      p: normalizeDate(
                        reservoir.nextRecommendedAction?.filterChange,
                        '-'
                      ),
                    },
                    {
                      h: 'Inspektion',
                      p:
                        normalizeDate(
                          reservoir.nextRecommendedAction?.inspection,
                          '-'
                        ) || '-',
                    },
                    {
                      h: 'Kontroll',
                      p: normalizeDate(
                        reservoir.nextRecommendedAction?.control,
                        '-'
                      ),
                    },
                    {
                      h: 'Robotrengöring',
                      p: normalizeDate(
                        reservoir.nextRecommendedAction?.robotCleaning,
                        '-'
                      ),
                    },
                    {
                      h: 'Manuell rengöring',
                      p: normalizeDate(
                        reservoir.nextRecommendedAction?.manualCleaning,
                        '-'
                      ),
                    },
                    {
                      h: 'Statusbedömning',
                      p: normalizeDate(
                        reservoir.nextRecommendedAction?.statusCheck,
                        '-'
                      ),
                    },
                  ]}
                />
              </Card>
              <ReservoirProtocolsList
                protocolsWithPDF={protocolsWithPDF}
                reservoir={reservoir}
                customerName={customerName}
              />
              {state.user.role === 'Admin' && (
                <Card>
                  <div className="customer-card-header">
                    <h3>Åtgärder för reservoar</h3>
                  </div>
                  <Button look="danger" small onClick={moveReservoirInit}>
                    Flytta
                  </Button>
                  <Button className="mleft05" small onClick={linkReservoirInit}>
                    Länka
                  </Button>
                </Card>
              )}
            </div>
            <div className="single-customer-right-content">
              <ReservoirAssetSection
                assetKey={'images'}
                refetch={getReservoir}
                customerId={query.customerId}
                reservoirId={reservoir._id}
                reservoir={reservoir}
                header={'Bilder'}
                fileType="image"
                AssetComponent={ImageViewer}
              />
              <ReservoirAssetSection
                assetKey={'drawings'}
                refetch={getReservoir}
                customerId={query.customerId}
                reservoirId={reservoir._id}
                reservoir={reservoir}
                header={'Ritningar'}
                AssetComponent={ReservoirDrawingCard}
              />
            </div>
          </div>
          {!!editData.active && (
            <OverlayBox
              header="Redigera reservoar"
              active
              headerContent={
                <Button
                  small
                  onClick={() => {
                    const saveButton = document.getElementById(
                      `saveButton_resid-${reservoir._id}`
                    );
                    if (saveButton) {
                      saveButton.click();
                    }
                  }}
                >
                  Spara
                </Button>
              }
              close={() => setEditData({ ...editData, active: false })}
              closeWarning
            >
              <ReservoirForm
                id={reservoir._id}
                formData={editData.formData}
                handleSubmit={updateReservoir}
              />
            </OverlayBox>
          )}
          <OverlayBox
            header="Nästa rekommenderade åtgärd"
            active={nextRecommendedActionEdit.active}
            close={() =>
              setNextRecommendedActionEdit({
                ...nextRecommendedActionEdit,
                active: false,
              })
            }
          >
            <form onSubmit={updateRecommendedActions}>
              <div className="dual">
                <div>
                  <p>Filterbyte</p>
                  <DateInput
                    value={nextRecommendedActionEdit.filterChange}
                    name="filterChange"
                    onChange={(e) =>
                      setNextRecommendedActionEdit({
                        ...nextRecommendedActionEdit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <p>Inspektion</p>
                  <DateInput
                    value={nextRecommendedActionEdit.inspection}
                    name="inspection"
                    onChange={(e) =>
                      setNextRecommendedActionEdit({
                        ...nextRecommendedActionEdit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="dual">
                <div>
                  <p>Kontroll</p>
                  <DateInput
                    value={nextRecommendedActionEdit.control}
                    name="control"
                    onChange={(e) =>
                      setNextRecommendedActionEdit({
                        ...nextRecommendedActionEdit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <p>Robotrengöring</p>
                  <DateInput
                    value={nextRecommendedActionEdit.robotCleaning}
                    name="robotCleaning"
                    onChange={(e) =>
                      setNextRecommendedActionEdit({
                        ...nextRecommendedActionEdit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="dual">
                <div>
                  <p>Manuell rengöring</p>
                  <DateInput
                    value={nextRecommendedActionEdit.manualCleaning}
                    name="manualCleaning"
                    onChange={(e) =>
                      setNextRecommendedActionEdit({
                        ...nextRecommendedActionEdit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <p>Statusbedömning</p>
                  <DateInput
                    value={nextRecommendedActionEdit.statusCheck}
                    name="statusCheck"
                    onChange={(e) =>
                      setNextRecommendedActionEdit({
                        ...nextRecommendedActionEdit,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <Button type="submit">Spara</Button>
            </form>
          </OverlayBox>
          <OverlayBox
            header={`Radera ${deleteReservoirPending.reservoir.basicInformation?.name}?`}
            active={deleteReservoirPending.active}
            close={() =>
              setDeleteReservoirPending({
                ...deleteReservoirPending,
                active: false,
              })
            }
          >
            <p>
              Vill du <strong>permanent radera</strong> reservoaren{' '}
              {deleteReservoirPending.reservoir.basicInformation?.name} och alla
              tillhörande filer?
            </p>
            <p>
              Det går <strong>inte</strong> att återskapa en raderad reservoar
              eller dess filer.
            </p>
            <Button med look="danger" onClick={deleteReservoir}>
              Radera
            </Button>
            <Button
              med
              onClick={() =>
                setDeleteReservoirPending({
                  ...deleteReservoirPending,
                  active: false,
                })
              }
            >
              Tillbaka
            </Button>
          </OverlayBox>
          <OverlayBox
            header="Flytta reservoar"
            close={() =>
              setMoveReservoirModal({
                ...moveReservoirModal,
                active: false,
              })
            }
            active={moveReservoirModal.active}
          >
            {moveReservoirModal.active ? (
              <MoveReservoirModal
                customerId={moveReservoirModal.customerId}
                reservoir={moveReservoirModal.reservoir}
              />
            ) : null}
          </OverlayBox>
          <OverlayBox
            header="Länka reservoar"
            close={() =>
              setLinkReservoirModal({
                ...linkReservoirModal,
                active: false,
              })
            }
            active={linkReservoirModal.active}
          >
            {linkReservoirModal.active ? (
              <LinkReservoirModal
                finish={linkReservoirModal.finish}
                customerId={linkReservoirModal.customerId}
                reservoir={linkReservoirModal.reservoir}
              />
            ) : null}
          </OverlayBox>
          <BtnNoStyle
            onClick={() =>
              setOverlayImage({
                ...overlayImage,
                active: false,
              })
            }
            className={`large-overlay-image ${
              overlayImage.active ? 'active' : ''
            }`}
          >
            <img src={overlayImage.image} alt="" />
          </BtnNoStyle>
        </StyledSingleReservoirPage>
      ) : null}
    </Layout>
  );
};

export default SingleReservoirPage;
