import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { request } from '../utils/request';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import Button from './Button';
import { getFileFromBuffer } from '../utils/getFileFromBuffer';

const StyledReservoirDrawingCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem;
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  button {
    white-space: nowrap;
  }
`;

const ReservoirDrawingCard = ({ assetId }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [metaData, setMetaData] = useState({});
  const [data, setData] = useState();

  const getFile = async () => {
    const { err, data } = await request({
      state,
      dispatch,
      isDoServer: true,
      path: `media/{token}/${assetId}`,
    });
    if (err) return;
    setMetaData(data.meta);
    setData(data.data.data);
  };

  const downloadFile = () => {
    if (!data || !Object.keys(metaData).length) return;
    getFileFromBuffer({
      buffer: data,
      type: metaData.type,
      download: true,
      filename: metaData.name,
    });
  };

  useEffect(() => {
    if (state.token && !Object.keys(metaData).length) getFile();
    // eslint-disable-next-line
  }, [state.token, assetId, metaData]);

  return (
    <StyledReservoirDrawingCard>
      <p title={metaData.name}>{metaData.name}</p>
      <Button noBg onClick={downloadFile}>
        Ladda ned
      </Button>
    </StyledReservoirDrawingCard>
  );
};

export default ReservoirDrawingCard;
